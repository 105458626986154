import blog1pic from '../assets/blog1.png';
import blog2pic from '../assets/blog2.png';
import blog3pic from '../assets/blog3.png';
import blog4pic from '../assets/blog4.png';

const BlogsData = [
    {
        title: 'Supporting Your Partner Through Trauma - With the Help of Couples Therapy',
        description: ['When one person in a relationship is grappling with trauma, it can affect the entire relationship. Couples therapy offers a path to navigate these challenges together while fostering empathy, understanding, connection, and healing.'],
        author: 'Kelsey Philippi',
        imageUrl: blog1pic
    },
    {
        title: 'How do I Help Someone With Postpartum Depression?',
        description: ['It can be difficult and scary when your partner or loved one has PPD. Learn some signs of PPD,  how to provide support, and how to encourage professional help - including a resource list.'],
        author: 'Emily Aleksy',
        imageUrl: blog2pic
    },
    {
        title: 'Navigating Health Anxiety: How Exposure Response Prevention Therapy Can Help',
        description: ['Health anxiety can consume your thoughts and disrupt daily life. Gabrielle writes about how ERP therapy might offer a path to relief.'],
        author: 'Gabrielle Trecek',
        imageUrl: blog3pic
    },
    {
        title: 'Building a Solid Foundation - Expectations in Relationships',
        description: ['We all want healthy relationships - but where do you even start?','Build solid foundation with healthy expectations! Read Jeni’s perspective on where to begin.'],
        author: 'Jennifer Everts Beier',
        imageUrl: blog4pic
    },
]

export default BlogsData;