import React from 'react';

import img1 from '../../assets/SpecialAreaCard1.jpg';
import img2 from '../../assets/SpecialAreaCard2.jpg';
import img3 from '../../assets/SpecialAreaCard3.jpg';
import img4 from '../../assets/SpecialAreaCard4.jpg';
import img5 from '../../assets/SpecialAreaCard5.jpg';
import img6 from '../../assets/SpecialAreaCard6.jpg';

const SpecialAreaCard = () => {
    return (
        <div className="special-area-cards grid md:grid-cols-3 middile:grid-cols-2 grid-rows-2 gap-[20px] p-[2.9vw] ">
            <div className='bg-[#EDD3E6] p-[1.9vw] flex flex-col items-center'>
                <img src={img1} alt='Special Area Card' className='w-full h-[20.1vw] object-cover mb-[1.5vw]' />
                <div className='flex flex-1 flex-col gap-[1vw]'>
                    <p className='font-Oswald text-center content__title'>Perinatal Counseling</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'><a className='underline' href='/'>Perinatal mental health</a> covers the time from when you begin to think about having kids to when you’re actually trying to raise (relatively) decent tiny humans. It’s not just postpartum depression! It can include anxiety, OCD, and even psychosis. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>A lot of times becoming a parent can even bring up our own experiences of being parented - making things really hard in a time that feels like it should be joyful. But it can feel a whole lot better than it does right now.</p>
                </div>
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-fit px-[1vw] pt-[1vw] pb-[1.3vw] leading-tight rounded-lg content__button mt-[2vw]'>Read More</button>
            </div>

            {/* 2nd Card */}
            <div className='bg-[#EDD3E6] p-[1.9vw] flex flex-col items-center'>
                <img src={img2} alt='Special Area Card' className='w-full h-[20.1vw] object-cover mb-[1.5vw]' />
                <div className='flex flex-1 flex-col gap-[1vw]'>
                    <p className='font-Oswald text-center content__title'>Fertility Counseling</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'><a className='underline' href='/'>Infertility</a> and fertility treatments can feel like they run your life. They creep into every space and moment in your day - and become exhausting. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>Nobody really seems to understand what you’re going through. It might even feel like it’s working out for everyone except you. Why is it so easy for them?</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>You deserve help with what you are going through, either alone or <a className='underline' href='/'>with your partner</a>.</p>
                </div>
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-fit px-[1vw] pt-[1vw] pb-[1.3vw] leading-tight rounded-lg content__button mt-[2vw]'>Read More</button>
            </div>

            {/* 3rd Card */}
            <div className='bg-[#EDD3E6] p-[1.9vw] flex flex-col items-center'>
                <img src={img3} alt='Special Area Card' className='w-full h-[20.1vw] object-cover mb-[1.5vw]' />
                <div className='flex flex-1 flex-col gap-[1vw]'>
                    <p className='font-Oswald text-center content__title'>Couples Therapy</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>Relationships are HARD. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>Like super hard. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>Sometimes it can even feel like your partner has become a stranger. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>It can get better. You can have the trust and joy and fun back in your relationship - no matter what stage of life you are in. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>Work with your partner in <a className='underline' href='/'>couples therapy</a> to make your relationship exactly what you want it to be again.</p>

                </div>
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-fit px-[1vw] pt-[1vw] pb-[1.3vw] leading-tight rounded-lg content__button mt-[2vw]'>Read More</button>
            </div>

            {/* 4th Card */}
            <div className='bg-[#EDD3E6] p-[1.9vw] flex flex-col items-center'>
                <img src={img4} alt='Special Area Card' className='w-full h-[20.1vw] object-cover mb-[1.5vw]' />
                <div className='flex flex-1 flex-col gap-[1vw]'>
                    <p className='font-Oswald text-center content__title'>OCD Therapy</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'><a className='underline' href='/'>Obsessive Compulsive Disorder</a> can feel relentless - and lonely, because nobody really understands how much you are going through. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>Your thoughts, obsessions, and compulsions might feel like they control your entire life.</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>A trained clinician can help you break free of the cycle of OCD - especially if they offer ERP (exposure response protocol) - the gold standard treatment for OCD.</p>
                </div>
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-fit px-[1vw] pt-[1vw] pb-[1.3vw] leading-tight rounded-lg content__button mt-[2vw]'>Read More</button>
            </div>

            {/* 5th Card */}
            <div className='bg-[#EDD3E6] p-[1.9vw] flex flex-col items-center'>
                <img src={img5} alt='Special Area Card' className='w-full h-[20.1vw] object-cover mb-[1.5vw]' />
                <div className='flex flex-1 flex-col gap-[1vw]'>
                    <p className='font-Oswald text-center content__title'>Trauma Therapy</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'><a className='underline' href='/'>Trauma</a> sucks. It gets its little claws into every area of your life and won’t let go. It can feel like it’s impacting everything around you.</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>We can help you move forward from it. You don’t have to carry it with you anymore. </p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>We treat people who have experienced birth trauma, abuse (verbal, sexual, financial, physical, or emotional), assaults, systemic trauma, religious trauma and more.</p>
                </div>
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-fit px-[1vw] pt-[1vw] pb-[1.3vw] leading-tight rounded-lg content__button mt-[2vw]'>Read More</button>
            </div>

            {/* 6th Card */}
            <div className='bg-[#EDD3E6] p-[1.9vw] flex flex-col items-center'>
                <img src={img6} alt='Special Area Card' className='w-full h-[20.1vw] object-cover mb-[1.5vw]' />
                <div className='flex flex-1 flex-col gap-[1vw]'>
                    <p className='font-Oswald text-center content__title'>Anxiety Therapy</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'><a className='underline' href='/'>Anxiety</a> can feel like it runs your life. It pops up just when you don’t want it to, and sticks around much longer than you’d like. It tells you what you can and can’t do and overwhelms your brain.</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>But it lies. You can take back that control and not feel like it’s running the show anymore.</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>And who isn’t super stressed right now?</p>
                    <p className='leading-relaxed font-Didact-Gothic whitespace-pre-wrap text-center item-content__description'>We can help you deal with either or both and start feeling better.</p>
                </div>
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-fit px-[1vw] pt-[1vw] pb-[1.3vw] leading-tight rounded-lg content__button mt-[2vw]'>Read More</button>
            </div>
        </div>
    );
};

export default SpecialAreaCard;