import React from 'react';
import { Banner, Blog, FollowUs, Footer, Introduction, LastPart, NavBar, SpecialArea } from '../components';
import { Helmet } from "react-helmet";

const HomePage = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home - True Home Now</title>
            </Helmet>
            <NavBar />
            <Banner />
            <Introduction />
            <SpecialArea />
            <Blog />
            <FollowUs />
            <LastPart />
            <Footer />
        </div>
    );
};

export default HomePage;