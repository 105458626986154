import React from 'react';

import item1 from '../assets/footeritem1.png';
import item2 from '../assets/footeritem2.png';
import item3 from '../assets/footeritem3.png';
import facebook from '../assets/Facebook.png';
import instagram from '../assets/instagram.png';

const Footer = () => {
    return (
        <div className='flex flex-col bg-[#eaebeb]'>
            <div className='flex w-full justify-center items-center'>
                <img src={item1} alt='item1' className='w-[10vw] h-auto' />
                <img src={item2} alt='item2' className='w-[10vw] h-auto' />
                <img src={item3} alt='item3' className='w-[15vw] h-auto' />
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-Brown-sugar pb-[1vw] pt-[0.5vw] px-[1vw]  leading-tight rounded-lg BannerBtn my-[3vw]'>Log in to the Client Portal</button>
                <a href='/' className='ml-[1.5vw] mr-[0.5vw]'>
                    <img src={facebook} alt='facebook' className='w-[2vw] h-auto' />
                </a>
                <a href='/'>
                    <img src={instagram} alt='instagram' className='w-[2vw] h-auto opacity-85' />
                </a>
            </div>
            <p className='font-Didact-Gothic text-[1vw]'>ERA Wellness, LLC — <a className='underline' href='/'>Privacy Policy</a> — <a className='underline' href='/'>Terms & Conditions</a></p>
        </div>
    );
};

export default Footer;