/** @format */
// import {
  
// } from "../pages";
import { path } from "./Constant";
export const allowedRoles = ["admin"];

const routes = [
 
];

export default routes;
