import React from 'react';
import PropTypes from 'prop-types';

const BlogCard = ({ title, description, author,imageUrl }) => {
    console.log(title,'title')  ;
    
    return (
        <div className="blog-card text-start">
            <img src={imageUrl} alt={title} className="blog-card-image w-full h-[13.5vw]" />
            <div className="blog-card-content flex flex-col gap-[0.5vw] mt-[0.5vw]">
                <a href='/' className="blog-card-title font-Didact-Gothic text-[1vw]">{title}</a>
                <div className='flex flex-col gap-[0.5vw]'>
                    {description.map((desc, index) => (
                        <p className='font-Didact-Gothic text-[0.7vw]'>{desc}</p>
                    ))}
                </div>
                <a href='/' className="blog-card-link font-Didact-Gothic text-[0.7vw]">Read More →</a>
                <p className="blog-card-author text-[0.7vw] font-Didact-Gothic opacity-50">{author}</p>
            </div>
        </div>
    );
};

BlogCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.array.isRequired,
    author: PropTypes.string.isRequired,
};

export default BlogCard;