import React from 'react';

import BannerBG from '../../assets/Banner.jpg'

const Banner = () => {
    return (
        <div className="h-[45vw] mx-[3vw] mb-[3vw] relative ">
            <img src={BannerBG} alt='banner' className='w-full h-full object-cover opacity-80' />
            <div className='absolute top-0 right-0 w-[69vw] h-full p-[3vmax] flex flex-col items-end justify-between'>
                <div className='flex flex-col items-end w-fit'>
                    <p className='font-eds-market-main-script text-[2.5vw] text-center w-full my-4 '>Therapy doesn’t have to suck.</p>
                    <p className='font-Oswald text-[2vw] text-end leading-tight'>You deserve to see a therapist that gets it and can help you heal.</p>
                    <p className='font-Oswald text-[2vw] text-end my-[1.7vw] leading-tight'>You deserve to be well.</p>
                    <p className='font-Oswald text-[2vw] text-end  leading-tight'>We can help.</p>
                </div>
                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white text-[1.4vw] font-eds-market-main-script w-[39.5vw] pb-[1.5vw] pt-[1vw] leading-tight rounded-lg BannerBtn'>Click to Get Started as a New Client</button>
                <p className='font-Oswald text-[1.8vw] text-end  leading-tight'> Individual and <span className='text-[#3b444e]'>Couples Therapy</span> in Whitefish Bay, WI or online for <span className='text-[#3b444e]'>Perinatal Counseling</span>, <span className='text-[#3b444e]'>Fertility Counseling</span>, <span className='text-[#3b444e]'>Trauma Therapy</span>, <span className='text-[#3b444e]'>OCD Therapy</span>, and <span className='text-[#3b444e]'>Anxiety Therapy</span>.</p>

            </div>
        </div>
    );
};

export default Banner;