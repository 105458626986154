import React from 'react';

import IntroductionIMG from '../../assets/Introduction.jpg'
import IntroductionIMG2 from '../../assets/Introduction2.jpg'


const Introduction = () => {
    return (
        <div className='bg-[#EDD3E6] w-full h-fit p-[3vmax]'>
            <p className='font-Oswald BannerFont text-start leading-tight'>You’re at your limit. You’re <span className='italic'>past</span> your limit. </p>
            <p className='font-Oswald BannerFont text-start leading-tight my-[1.6vw]'>You’re running on empty. You feel like you’re totally just <span className='italic'>losing it</span>. </p>
            <div className='flex flex-col mt-[6.3vw] tracking-wide'>
                <p className='font-Didact-Gothic sqsrte-large'>Things are really freaking hard right now, but they don’t have to stay that way. You deserve to heal. </p>
                <p className='font-Didact-Gothic sqsrte-large my-[0.85vw]'>Is therapy hard? YUP. But not as hard as continuing to live with the way things are now. You’ve been suffering for too long.  </p>
                <p className='font-Didact-Gothic sqsrte-large'>You deserve to leave this behind, heal, and start enjoying life.  </p>
            </div>
            <div className='grid grid-cols-3 mx-[7.8vw] gap-[4.5vw] my-[7vw]'>
                <div className='col-span-2 w-full text-start gap-[1.6vw] flex flex-col'>
                    <p className='font-site-navigation font-Oswald'>At ERA Wellness we specialize in therapy for <a href='/' className='underline text-[#3B444E]'>Perinatal Counseling</a> (<a href='/' className='underline text-[#3B444E]'>Fertility Counseling</a>, <a href='/' className='underline text-[#3B444E]'>pregnancy</a>, <a href='/' className='underline text-[#3B444E]'>postpartum</a>, or <a href='/' className='underline text-[#3B444E]'>parenting</a>), <a href='/' className='underline text-[#3B444E]'>Trauma Therapy</a>, <a href='/' className='underline text-[#3B444E]'>OCD Therapy</a>, and <a href='/' className='underline text-[#3B444E]'>Anxiety Therapy</a>. </p>
                    <p className='font-site-navigation font-Oswald'>We offer both individual and <a href='/' className='underline text-[#3B444E]'>Couples Therapy</a> - but see clients  for a wide variety of mental health concerns and situations.</p>
                </div>
                <div className=''>
                    <img src={IntroductionIMG} alt='Introduction' className='w-full h-full object-contain' />
                </div>
            </div>
            <p className='font-Didact-Gothic sqsrte-large w-full px-[17%] tracking-wide text-center leading-[1.8em]'>This includes marriage or divorce, parenthood, fertility struggles, infidelity, life and work stress, birth trauma, postpartum depression and anxiety, chronic illness, seasonal depression, raising kids, neurodivergence, family conflict — basically anything you could look back on and say, “Shit, that was hard.” </p>
            <div className='flex flex-col gap-[1.6vw] ml-[4vw] mt-[7vw]'>
                <p className='font-Oswald h3 text-start leading-tight'>To us, the most important thing about choosing a therapist is finding somebody who really gets you. </p>
                <p className='font-Oswald h3 text-start leading-tight'>Someone who can see the pain or stress you are in and help you through it.</p>
            </div>
            <div className='flex flex-col gap-4  w-full px-[12%] my-[7vw]'>
                <p className='font-Didact-Gothic sqsrte-large tracking-wide text-center leading-[1.8em]'>Maybe you’ve tried therapy before - even <span className='font-bold italic'>a lot</span> of times, but it just didn’t quite get at what you needed it to. You may have even left feeling worse than before. Therapy doesn’t have to be like that - and it <span className='italic'>shouldn’t</span> be like that. You deserve a therapist who does truly understand you and what you’ve been through. You deserve to feel validated and worthy and believed. </p>
                <p className='font-Didact-Gothic sqsrte-large tracking-wide text-center leading-[1.8em]'>Our clinicians understand that and partner with each individual client to help you in the way that is right for you. No cookie-cutter generalist therapy here.</p>
            </div>
            <div className='flex flex-col gap-[1.6vw] ml-[4vw] '>
                <p className='font-Oswald h3 text-start leading-tight'>And let’s face it: You’re busy. Therapy needs to work for your life. </p>
                <p className='font-Oswald h3 text-start leading-tight'>If it doesn’t fit, it’s just not going to happen. </p>
            </div>
            <p className='font-Didact-Gothic sqsrte-large w-full px-[3.5%] tracking-wide text-center leading-[1.8em] mt-8'>ERA can help make it happen: we offer <a href='/' className='underline text-[#3B444E]'>in-person</a> individual and couples therapy sessions at our office in Whitefish Bay - <span className='italic'>and</span> <a href='/' className='underline text-[#3B444E]'>telehealth sessions online</a> for those either local to us (Cedarburg, Grafton, <a href='/' className='underline text-[#3B444E]'>Mequon</a>, Glendale, <a href='/' className='underline text-[#3B444E]'>Shorewood</a>, Milwaukee, Fox Point, Bayside, River Hills) or anywhere in the state of Wisconsin.</p>
            <div className='grid grid-cols-3 ml-[12vw] mr-[6vw] gap-[4.5vw] my-[7vw]'>
                <div className=''>
                    <img src={IntroductionIMG2} alt='Introduction' className='w-full h-full object-contain' />
                </div>
                <div className='col-span-2 w-full h-full text-start gap-[1.6vw] flex flex-col justify-center'>
                    <p className='h3 font-Oswald'>We use techniques like CBT (cognitive behavioral therapy), ERP (exposure response prevention), Gottman Method Couple’s Therapy, EMDR, and Brainspotting - because not everybody needs the same kind of tools to help them heal.</p>
                </div>
            </div>
            <p className='font-Didact-Gothic sqsrte-large w-full px-[10%] tracking-wide text-center leading-[1.8em] mt-8'>When you’re ready to connect, click on <a href='/' className='underline text-[#3B444E]'>“Submit a New Client Inquiry”</a> and enter your information or call us directly at <a href='/' className='underline text-[#3B444E]'>414.301.3234</a> to find out more about how we can help.</p>
            <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-[35vw] pt-[1vw] pb-[1.3vw] leading-tight rounded-lg  my-[3vw]'>Submit a New Client Inquiry</button>


        </div>
    );
};

export default Introduction;