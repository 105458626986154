import React from 'react';
import SpecialAreaCard from './SpecialAreaCard';

import NewClient from '../../assets/NewClient.jpg'

const SpecialArea = () => {
    return (
        <div className="special-area mt-[4vw]">
            <p className='font-Oswald list-section-title'>Read more about each area we specialize in:</p>
            <SpecialAreaCard />
            <div className='grid grid-cols-3 mx-[4.9vw] mt-[7vw] gap-[4vw]'>
                <img src={NewClient} alt='new client' className='col-span-1 w-full h-[95%] object-cover' />
                <div className='col-span-2 flex flex-col gap-[1.5vw] items-center'>
                    <p className='font-Oswald text-[2vw]'>Ready to move forward and put some of that behind you?</p>
                    <p className='font-Didact-Gothic sqsrte-large'>Click the button below to take the first step and fill out our New Client Inquiry Form.</p>
                    <p className='font-Didact-Gothic sqsrte-large'>You can feel better. We can help. Start now.</p>
                    <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-site-navigation font-eds-market-main-script w-fit p-[1vw] pb-[1.3vw] leading-tight rounded-lg content__button mt-[3vw]'>Submit a New Client Inquiry</button>
                </div>
            </div>
        </div>
    );
};

export default SpecialArea;