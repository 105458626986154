import React from 'react';

import logo from '../assets/logo3.png'
import { useLocation } from 'react-router-dom';
import { path } from '../utils/Constant';

const NavBar = () => {

    const location = useLocation();

    return (
        <div className='flex justify-between items-center py-[1vw] px-[3vw] '>
            <img src={logo} alt='logo' className='w-auto h-[10.4vw]' />
            <div className='flex font-Oswald gap-[2.5vw]'>
                <a href='/' className={`text-[1.55vw] ${location.pathname = path.HOME ? 'underline' : ''}`}>Home</a>
                <a href='/' className='text-[1.55vw]'>Specialties</a>
                <a href='/' className='text-[1.55vw]'>Services</a>
                <a href='/' className='text-[1.55vw]'>About ERA</a>
                <a href='/' className='text-[1.55vw]'>Blog</a>

                <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white whitespace-nowrap font-eds-market-main-script h-[2.85vw] w-[8.3vw] leading-tight rounded-lg text-[1.1vw]'>Contact ERA</button>
            </div>
        </div>
    );
};

export default NavBar;