import React from 'react';

import BG from '../../assets/FinalBG.png';
import PromoBG from '../../assets/PromoBG.jpg'
import logo2 from '../../assets/logo3.png';
import ceo from '../../assets/ceo.jpg';
const LastPart = () => {
    return (
        <div className='flex flex-col bg-[#eaebeb] items-center'>
            <img src={BG} alt='final bg' className='w-full h-[8vw] object-fill' />
            <p className='font-Oswald list-section-title mt-[2vw] mb-[5vw]'>No matter what’s going on - you deserve to feel better. We can help.</p>
            <div className='relative w-full px-[3vw]'>
                <img src={PromoBG} alt='promo bg' className='w-full h-[17.8vw] object-cover' />
                <div className='absolute top-0 left-0 w-full h-full flex px-[6vw] items-center gap-[4vw]'>
                    <p className='w-[45%] font-Oswald text-[1.55vw] text-center leading-tight'>
                        Located in Whitefish Bay, WI, we offer in-person individual and <a className='underline' href='/'>couples</a> psychotherapy to the greater Milwaukee area.
                        We also serve all of Wisconsin via telehealth for <a className='underline' href='/'>Perinatal Counseling</a>, <a className='underline' href='/'>Fertility Counseling</a>, <a className='underline' href='/'>Trauma Therapy</a>, <a className='underline' href='/'>OCD Therapy</a>, and <a className='underline' href='/'>Anxiety Therapy</a>.
                    </p>
                    <button className='bg-[#C8B086] hover:bg-[#e5cb9d] text-white font-eds-market-main-script w-fit h-fit py-[1.2vw] px-[6vw] leading-tight rounded-lg text-[1.8vw]'>Submit a New Client Inquiry</button>
                </div>
            </div>
            <div className='w-[93vw] h-[1px] bg-black my-[4vw]'></div>
            <div className='flex justify-center items-center'>
                <div className='flex flex-col text-center gap-[0.5vw]'>
                    <img src={logo2} alt='logo' className='w-[38.5vw] h-auto' />
                    <a href='/' className='underline font-Didact-Gothic text-[1vw]'>316 E. Silver Spring Drive, Suite 227 Whitefish Bay, WI 53217</a>
                </div>
                <img src={ceo} alt='ceo' className='w-[11vw] h-[15vw] object-cover mr-[1vw] ml-[4vw]' />
                <div className='flex flex-col text-start h-[15vw]'>
                    <p className='font-julius-sans-one text-[1.8vw]'>Emily Aleksy LCSW, PMH-C</p>
                    <div className='w-[18vw] h-[1px] bg-black'></div>
                    <p className='font-Didact-Gothic text-[1vw] mt-[2vw]'>Owner/Psychotherapist emily@erawellnesstherapy.com</p>
                </div>
            </div>
            <div className='w-[93vw] h-[1px] bg-black mt-[4vw]'></div>
        </div>
    );
};

export default LastPart;