import React from 'react';

import IG1 from '../../assets/IG1.jpeg';
import IG2 from '../../assets/IG2.jpeg';
import IG3 from '../../assets/IG3.jpeg';
import IG4 from '../../assets/IG4.jpeg';
import playBtn from '../../assets/play-button.png';

const FollowUs = () => {
    return (
        <div className="follow-us my-[5vw]">
            <p className='text-[2.5vw] text-start font-eds-market-main-script ml-[3vw] mb-[2vw]'>Follow us on <a href='/' className='underline'>Instagram</a></p>
            <div className="social-links grid grid-cols-4 grid-row-1 mx-[3vw] gap-[1vw] ">
                <a href="https://www.instagram.com/p/C-SuC4-NXJP/" target="_blank" rel="noopener noreferrer">
                    <img src={IG1} alt='IG1' />
                </a>
                <a href="https://www.instagram.com/p/C-P7g97yGfQ/" target="_blank" rel="noopener noreferrer">
                    <img src={IG2} alt='IG2' />
                </a>
                <a href="https://www.instagram.com/p/C-NkqiWub-y/" target="_blank" rel="noopener noreferrer">
                    <img src={IG3} alt='IG3' />
                </a>
                <a href="https://www.instagram.com/reel/C-JAGe5Joi1/embed/?autoplay=1" target="_blank" rel="noopener noreferrer" className='w-full h-full flex justify-center relative'>
                    <img src={IG4} alt='IG4' className='object-contain w-auto h-[22.6vw]' />
                    <img src={playBtn} alt='play button' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[3vw] h-[3vw]' />
                </a>
            </div>
        </div>
    );
};

export default FollowUs;