import React from 'react';

import BGTop from '../../assets/BlogBGTop3.png';
import BGBot from '../../assets/BlogBGBot3.png';
import BlogsData from '../../Data/HomePageData';
import BlogCard from './BlogCard';

const Blog = () => {
    return (
        <div className='mt-[2vw]'>
            <img src={BGTop} alt='blog top' className='w-full h-[10vw] object-fill' />
            <div className='w-full h-fit bg-[#EDD3E6] pb-[6vw]'>
                <p className='text-[2.5vw] text-start font-eds-market-main-script ml-[3vw] py-[3vw]'>Check out our <a className='underline' href='/'>blog</a></p>
                <div className='w-full grid grid-cols-6 px-[6vw] gap-[1vw]'>
                    {BlogsData.map((blog, index) => (
                        <BlogCard key={index} title={blog.title} description={blog.description} imageUrl={blog.imageUrl} author={blog.author} />
                    ))}
                </div>
            </div>
            <img src={BGBot} alt='blog bottom' className='w-full h-[10vw] object-fill' />
        </div>
    );
};

export default Blog;